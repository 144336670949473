import React from 'react';
import { arrayOf, bool, string, shape, number, oneOf } from 'prop-types';
import { CarouselSnappedSlide } from '@andes/carousel-snapped';

import Image from '../../../commons/image';
import withTracker from '../../../commons/with-tracker';
import useViewability from '../../../commons/viewability-hook/use-viewability';
import ScriptPrint from './script-print';

function CarouselItem(props) {
  const {
    title = '',
    permalink = '',
    picture,
    actions = {
      content_link: {
        link: '',
      },
    },
    event_tracking = {
      print: [],
    },
    index,
    itemsLenght,
    isEnabledNewMainSlider,
    verification_resources,
    layout,
    nonce,
    ...other
  } = props;

  const [firstPrintUrl] = event_tracking?.print ?? [];
  const ref = React.useRef(null);


  useViewability(ref, event_tracking, verification_resources);

  const isLazyLoad = layout === 'mobile' ? (index > 1 && index < itemsLenght - 1) : index > 0;
  return (
    <CarouselSnappedSlide
      key={`sale-product-${title
        ? title.replace(' ', '-').toLowerCase() : String(index)}`}
      {...other}
    >
      <a ref={ref} href={actions?.content_link?.link || permalink}>
        {picture?.originalSrc ? (
          // eslint-disable-next-line nordic/no-img-tags
          <img
            src={picture?.src}
            data-original-src={picture.originalSrc}
            className={isEnabledNewMainSlider ? 'image-main-slider' : null}
            alt={title || `main_slider_web_ml_${index}`}
          />
        ) : (
          <Image
            className={isEnabledNewMainSlider ? 'image-main-slider' : null}
            src={picture?.src}
            src2x={picture?.src2x}
            originalSrc={picture?.originalSrc}
            alt={title || `main_slider_web_ml_${index}`}
            lazyLoad={isLazyLoad}
            preload={!isLazyLoad}
          />)}
      </a>
      {firstPrintUrl?.url && <ScriptPrint url={firstPrintUrl?.url} nonce={nonce} />}
    </CarouselSnappedSlide>
  );
}

CarouselItem.propTypes = {
  title: string,
  permalink: string,
  picture: shape({
    src: string,
    originalSrc: string,
  }).isRequired,
  actions: shape({
    content_link: shape({
      link: string,
    }),
  }),
  event_tracking: shape({
    print: arrayOf(
      shape({
        url: string,
      }),
    ),
    view: arrayOf(
      shape({
        url: string,
      }),
    ),
  }),
  verification_resources: arrayOf(shape({})),
  itemsLenght: number,
  index: number,
  isEnabledNewMainSlider: bool,
  nonce: string,
  layout: oneOf(['desktop', 'mobile']).isRequired,
};

export default withTracker(CarouselItem);
