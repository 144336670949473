import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectI18n } from 'nordic/i18n';
import { CarouselSnapped } from '@andes/carousel-snapped';
import classNames from 'classnames';

import withTracker from '../../../commons/with-tracker';
import CarouselItem from './carousel-item';

const DESKTOP = 'desktop';
const MOBILE = 'mobile';

const carouselSettings = {
  global: {
    autoplaySpeed: 5000,
    arrowsVisible: false,
    slidesPerView: 1,
    spacing: 12,
  },
  mobile: {
    scroll: 'visible',
    speed: 275,
    pagination: false,
    type: 'content',
  },
  desktop: {
    scroll: 'hidden',
    speed: 500,
    pagination: true,
    type: 'full',
  },
};

function Carousel(props) {
  const {
    layout,
    i18n,
    elements,
    is_enabled_new_main_slider: isEnabledNewMainSlider,
    is_enabled_new_home_web_mobile: isEnableNewHomeWebMobile,
    nonce,
  } = props;
  const ariaLabel = i18n.gettext('novedades principales');
  const { global, mobile, desktop } = carouselSettings;

  useEffect(() => {
    const header = document.querySelector('.nav-header');
    if (header && isEnableNewHomeWebMobile) {
      header.classList.add('isEnableNewHomeWebMobile');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames(
        'andes-carousel-snapped__exhibitor-wrapper', {
          'andes-carousel-snapped__hero': isEnabledNewMainSlider,
          'andes-carousel-snapped__new-home': isEnableNewHomeWebMobile,
          'andes-carousel-snapped__hero--desktop': layout === DESKTOP,
        },
      )}
    >
      <CarouselSnapped
        {...props}
        scroll={
          (layout === MOBILE && isEnabledNewMainSlider) || isEnableNewHomeWebMobile
            ? mobile.scroll
            : desktop.scroll
        }
        srLabel={ariaLabel}
        spacing={global.spacing}
        slidesPerView={global.slidesPerView}
        speed={layout === MOBILE ? mobile.speed : desktop.speed}
        type={
          (layout === MOBILE && isEnabledNewMainSlider) || isEnableNewHomeWebMobile
            ? mobile.type
            : desktop.type
        }
        autoplay={{ speed: global.autoplaySpeed }}
        arrows={layout === DESKTOP && { visibility: global.arrowsVisible ? 'always' : 'hover' }}
        pagination={
          (layout === MOBILE && isEnabledNewMainSlider) || isEnableNewHomeWebMobile
            ? mobile.pagination
            : desktop.pagination
        }
        infinite
      >
        {elements.map((product, index, array) => (
          <CarouselItem
            key={`sale-product-${
              product.title
                ? product.title.replace(/ /g, '-').toLowerCase() + index
                : product.order + index
            }`}
            {...product}
            index={index}
            {...isEnabledNewMainSlider}
            itemsLenght={array.length}
            layout={layout}
            nonce={nonce}
          />
        ))}
      </CarouselSnapped>
      <div className="gradient" />
    </div>
  );
}

Carousel.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.object).isRequired,
  layout: PropTypes.oneOf([DESKTOP, MOBILE]).isRequired,
  i18n: PropTypes.object.isRequired,
  is_enabled_new_main_slider: PropTypes.bool,
  is_enabled_new_home_web_mobile: PropTypes.bool,
  autoplay: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  nonce: PropTypes.string,
};

export default injectI18n(withTracker(Carousel));
